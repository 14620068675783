<template>
  <div class="">
    <div class="title__battery mb-2">{{value + unit}}</div>
    <i class="fas fa-battery-three-quarters baterry__1"></i>
  </div>
</template>
<script>
export default {
  props: ["value", "unit"]
};
</script>
