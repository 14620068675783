/* import { loadLiquidFillGauge } from "@/pluggin"; */

export default {
  namespaced: true,
  state:
  {
    devices: [],
    layout:[],
    index: 0,
    colNum: 1
  },

  mutations:
  {
    setDevices(state, device){

      let device1 = state.devices.find(el => el.mac == device.mac);

      if(device1){
        let index = state.devices.findIndex(el => el.mac === device.mac);

        state.devices[index].mac = device.mac;
        state.devices[index].pcbname = device.pcbname;
        state.devices[index].sensor = device.sensor;
        state.devices[index].timestamp = device.timestamp;

      }else {

        state.index = state.layout.length;

        state.layout.push({
          x: 0,
          y: state.layout.length * 2,
          w: 12,
          h: 12,
          i: state.index,
        });

        state.devices.push(device);
      }

      /* console.log(state.devices); */
    }
  },
  
  actions:
  {
    loadDevices({commit}, payload){
      commit("setDevices", payload);
    }
  }
};