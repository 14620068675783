<template>
  <div>
    <Header />
    <div class="container-fluid">
      <div class="title__section text-center mb-4">Mis Dispositivos</div>
      <template v-if="devices.length > 0">
        <div class="mb-4">
          <Widget :devices="devices" :layout="layout" />
        </div>
      </template>
      <template v-else>
        <div class="container__home">
          <div class="spinner-border spinner__1" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Widget from "@/components/widget/Widget";
import { mapState } from "vuex";
export default {
  name: "Dashboard",

  data() {
    return {};
  },

  components: {
    Header,
    Widget,
  },

  computed: {
    ...mapState("devices", ["devices", "layout"]),
  },
};
</script>

<style></style>
