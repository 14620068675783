<template>
  <div>
    <header class="header">
      <div class="container-fluid header__container">
        <div class="left__area">
          <router-link to="/">
            <img class="logo__img" src="@/assets/img/HelpSmart-01.png" alt="logo" loading="lazy">
          </router-link>
        </div>
        <div class="right__area">
            <div class="form__select" v-if="mac">
              <select 
                class="select__"
                v-model="dateSelect"
                @change="generateChart(dateSelect)"
              >
                <option value="">
                  Selecciona 
                </option>
                <option
                  v-for="(item, index) in items"
                  :key="index" 
                  :value="item.id"
                >
                  {{item.name}}
                </option>
              </select>
            </div>
            <a class="item__generator" v-if="mac" @click="calendarOpen">
              <i class="fas fa-calendar-alt"></i>
            </a>
            <div class="icono__user" @click="profileOpen">
              <i class="fas fa-user-circle"></i>
            </div>
        </div>
      </div>
    </header>

    <div class="panel__date p-2" :class="{'active': isActive}">
      <div class="d-flex justify-content-end">
        <div class="btn__closed" @click="panelClosed">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <template v-if="isCalendar">
        <span class="calendar__panel">
          <DatePicker 
            v-model="dateInit"
            is-expanded
            :attributes='attrs'
            color="gray"
            mode="date"
            :model-config='modelConfig'
            :max-date='new Date()'
            @input="getDay"
          >
          </DatePicker>
        </span>
      </template>
      <template v-else>
        <div class="text-center">
          <span class="profile__photo">
            <i class="fas fa-user-circle"></i>
          </span>
          <div class="profile__name mb-2">Bryan de Jesús Blanco Villero</div>
          <div class="profile__email mb-3">bryan@gmail.com</div>
        </div>
        <div class="text-left">
          <router-link to="/dashboard" class="profile__item">
            <i class="fas fa-sim-card mr-3"></i>
            Mis dispositivos
          </router-link>
        </div>
        <div class="d-flex justify-content-center">
          <div class="profile__button text-center">
            <i class="fas fa-sign-out-alt mr-3"></i> 
            Cerrar sesión
          </div>
        </div>
      </template>
    </div>
    <div class=" p-2"></div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es");
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import mqtt from "mqtt";
export default {
  name: "Header",

  data(){
    const me = this;
    return {
      mac: me.$route.params.mac,
      area: me.$route.params.area,
      enterprise: me.$route.params.enterprise,
      dateInit: null,
      isActive: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      attrs: [{
        highlight: 'green',
        dates: new Date(),
      }],
      items: [
        {
          id: 1,
          name: "Últimos 15 minutos"
        },
        {
          id: 2,
          name: "Últimos 30 minutos"
        },
        {
          id: 3,
          name: "Última 1 hora"
        },
        {
          id: 4,
          name: "Hoy"
        },
        {
          id: 5,
          name: "Ayer"
        },
        {
          id: 6,
          name: "Los últimos 7 días"
        },
        {
          id: 7,
          name: "Los últimos 15 días"
        },
        {
          id: 8,
          name: "Últimos 30 días"
        },
        {
          id: 9,
          name: "Últimos 90 días"
        },
        {
          id: 10,
          name: "Último año"
        }
      ],
      dateSelect: "",
      isCalendar: false,
      client: null,
      devices: []
    }
  },

  components: {
    DatePicker
  },

  methods: {
    calendarOpen(){
      this.isCalendar = true;
      this.isActive = true;
    },
    profileOpen(){
      this.isCalendar = false;
      this.isActive = true;
    },
    panelClosed(){
      this.isActive = false;
    },
    generateChart(id) {
      const me = this;
      let initial_date, final_date, interval;

      const last15Minutes = (moment().subtract(15, 'minutes'), moment().subtract(15, 'minutes')).format("YYYY-MM-DD HH:mm:ss");
      const last30Minutes = (moment().subtract(30, 'minutes'), moment().subtract(30, 'minutes')).format("YYYY-MM-DD HH:mm:ss");
      const last1Hour = (moment().subtract(1, 'hour'), moment().subtract(1, 'hour')).format("YYYY-MM-DD HH:mm:ss"); 
      const today =  (moment(), moment()).format("YYYY-MM-DD HH:mm:ss");
      const today2 =  (moment(), moment()).format("YYYY-MM-DD");
      const yesterday = (moment().subtract(1, 'days'), moment().subtract(1, 'days')).format("YYYY-MM-DD");
      const last7Days =  (moment().subtract(6, 'days'), moment().subtract(7, 'days')).format("YYYY-MM-DD");
      const last15Days =  (moment().subtract(14, 'days'), moment().subtract(15, 'days')).format("YYYY-MM-DD");
      const last30Days = (moment().subtract(29, 'days'), moment().subtract(30, 'days')).format("YYYY-MM-DD");
      const last90Days = (moment().subtract(89, 'days'), moment().subtract(90, 'days')).format("YYYY-MM-DD");
      const lastYear =  (moment().subtract(12, 'month').startOf('month'), moment().subtract(12, 'month').endOf('month')).format("YYYY-MM-DD");

      if(id == 1){

        initial_date = last15Minutes;
        final_date = today;
        interval = 'null';

      } else if(id == 2){

        initial_date = last30Minutes;
        final_date = today;
        interval = 'null';

      } else if(id == 3){

        initial_date = last1Hour;
        final_date = today;
        interval = 'null';

      } else if(id == 4){

        initial_date = today2;
        final_date = today;
        interval = 'null';

      } else if(id == 5){

        initial_date = yesterday;
        final_date = yesterday;
        interval = 'null';

      } else if(id == 6){

        initial_date = last7Days;
        final_date = yesterday;
        interval = '6h'

      } else if(id == 7){

        initial_date = last15Days;
        final_date = yesterday;
        interval = '6h'

      } else if(id == 8){

        initial_date = last30Days;
        final_date = yesterday;
        interval = '6h'

      } else if(id == 9){

        initial_date = last90Days;
        final_date = yesterday;
        interval = '6h'

      } else if(id == 10){
        
        initial_date = lastYear;
        final_date = yesterday;
        interval = '6h'

      }

      me.dateTimeInit = initial_date
      me.datetimeFin = final_date

      me.$router.push({ 
        name: "report-graphics", 
        params: { 
          mac: me.mac,
          interval: interval,
          area: me.area,
          enterprise: me.enterprise,
          dateTimeInit: initial_date,
          datetimeFin: final_date
        } 
      });

    },
    getDay () {
      const me = this;
      me.panelClosed();

      me.$router.push({ 
        name: "report-graphics", 
        params: { 
          mac: me.mac, 
          area: me.area,
          interval: 'null',
          enterprise: me.enterprise, 
          dateTimeInit: me.dateInit,
          datetimeFin: me.dateInit
        } 
      });
    },
    startMqttClient(){
      const me = this;
      const options = {
        host: "iotm.helpmedica.com",
        port: 8088,
        endpoint: "/mqtt",
        clean: true,
        connectTimeout: 5000,
        reconnectPeriod: 5000,
        clientId: "app_vue",
        username: "device1.helpmedica",
        password: "device1.helpmedica"
      }

      /* const topic = "Sedenti/+/#"; */
      /* "Sedenti/subestacion1/ac:67:b2:29:33:14"; */
      const topic = "helpsmart/+/#";
      const connectUrl = "ws://" + options.host + ":" + options.port + options.endpoint;

      try {
        me.client = mqtt.connect(connectUrl, options);
      } catch (error) {
        console.error(error);
      }

      me.client.on("connect", () => {
        /* console.log("Connection succeeded"); */

        me.client.subscribe(topic, {qos:0}, (err) => {
          if(err){
            console.log("Error in subscription", err);
            return;
          }

          /* console.log("Subscription success"); */
        })
      });

      me.client.on("error", error => {
        /* console.log("Connection failed", error); */
      });

      me.client.on("reconnect", (error) => {
        /* console.log("Reconnecting", error); */
      });

      /* let prueba = me.client.publish("Sedenti", "+peticion:TR\n");

      console.log(prueba); */

      me.client.on("message", (topic, message) => {

        /* console.log("topic =>", topic); */

        let topico = topic.split("/");
        let topicNames = topico[0].toUpperCase() + "-" + topico[1].toUpperCase();
        let enterprise = topico[0].toUpperCase();
        let device = JSON.parse(message.toString());
        device.title = topicNames + "-" + device.pcbname.toUpperCase();
        device.enterprise = enterprise;
        me.$store.dispatch("devices/loadDevices", device);
        
      });
    }
  },

  mounted(){
    this.startMqttClient();
  }
}
</script>
