<template>
  <div>
    <img
      v-if="isOpen"
      height="100"
      src="@/assets/img/opened-door.png"
    />
    <img
      v-else
      height="100"
      src="@/assets/img/closed-door.png"
    />
    <switch-indicator 
      :value="value"
      :text="text"
    />
  </div>
</template>
<script>
import SwitchIndicator from './SwitchIndicator';

export default {
  components: {
    SwitchIndicator,
  },
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      text: {
        text1: 'Cerrado',
        text2: 'Abierto'
      },
    }
  },
};
</script>
