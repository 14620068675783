<template>
  <div>
    <img
      height="100"
      src="@/assets/img/planta-electrica.png"
    />
    <switch-indicator 
      :value="value"
      :text="text"
    />
  </div>
</template>
<script>
import SwitchIndicator from './SwitchIndicator';

export default {
  components: {
    SwitchIndicator,
  },
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      text: {
        text1: 'Apagado',
        text2: 'Encendido'
      },
    }
  },
};
</script>
