<template>
  <div class="container">
    <h1>
      Opps, it seems that this page does not exist.
    </h1>
  </div>
</template>

<script>
export default {
  name: "ErrorPage"
}
</script>

<style>

</style>