
const customComponentActions = {
  methods: {
    validationForm(validation, field){
      if(validation.$dirty){
        document.getElementById(`${field}`).classList.remove('correcto');
        document.getElementById(`${field}`).classList.add('incorrecto');
      }
      if(!validation.$invalid){
        document.getElementById(`${field}`).classList.remove('correcto');
        document.getElementById(`${field}`).classList.add('incorrecto');
      }
    }
  }
}


export {
  customComponentActions
};