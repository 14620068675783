import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/login/Login";
import Dashboard from "@/app/Dashboard"
import ErrorPage from "@/components/error/Error";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/report-graphics/:mac/:interval/:area/:enterprise/:dateTimeInit/:datetimeFin",
    name: "report-graphics",
    component: () =>
      import(/* webpackChunkName: "report-graphics"*/ "@/app/ReportGraphics"),
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;