import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSmartWidget from 'vue-smart-widget';
import RepositoryProvider from "@/providers/RepositoryProvider";

Vue.config.productionTip = false;


Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueSmartWidget);

new Vue({
  provide: RepositoryProvider,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
