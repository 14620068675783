<template>
  <div>
    <div class="container__login">
      <div class="content__logos">
        <img class="" src="@/assets/img/logo2.png" alt="Solticolombia">
      </div>
      <div class="login__content">
        <img src="@/assets/img/Letras Helpsmart-01.png" alt="Help Smart" />
        <h2 class="title__login">Inicio de sesión</h2>
        <div class="panel__msg--error" v-if="errorMessage">
          <small class="msg__error mt-1">{{ errorMessage }}</small>
          <button class="btn btn__close--panel" @click="closedPanel">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <form @submit.prevent="login">
          <div id="email" :class="validationForm($v.user.email, field.email)">
            <div class="input__field">
              <span class="icono__input">
                <i class="fas fa-envelope"></i>
              </span>
              <input
                type="email"
                placeholder="Correo electronico"
                v-model="$v.user.email.$model"
                @blur="$v.user.email.$touch()"
              />
            </div>
            <small
              class="error__formulario"
              v-if="!submitted && !$v.user.email.required"
            >
              Campo requerido.
            </small>
            <small
              class="error__formulario"
              v-if="!submitted && !$v.user.email.email"
            >
              Ingrese una dirección de correo válida.
            </small>
          </div>
          <div
            id="password"
            :class="validationForm($v.user.password, field.password)"
          >
            <div class="input__field">
              <span class="icono__input">
                <i class="fas fa-lock"></i>
              </span>
              <input
                type="password"
                placeholder="Contraseña"
                v-model="$v.user.password.$model"
                @blur="$v.user.password.$touch()"
              />
            </div>
            <small
              class="error__formulario"
              v-if="!submitted && !$v.user.password.required"
            >
              Campo requerido.
            </small>
          </div>
          <div class="olvidar__password">
            <a v-b-modal.modal-ask-code class="a__login"
              >¿Olvidaste tú contraseña?</a
            >
          </div>
          <button
            type="submit"
            class="btn btn__ingresar waves btn-block mb-4 mt-3"
          >
            Ingresar
            <span
              :class="{
                'spinner-border spinner-border-sm  mr-2 spinnerMas': isLoading,
              }"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </form>
      </div>
    </div>
    <div class="footer__login">
      <img  src="@/assets/img/ByHelpmedica.png" alt="HelpMedica" />
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
/* import { getAuth, signInWithEmailAndPassword } from "firebase/auth"; */
import { customComponentActions } from "@/mixins/actions";
export default {
  name: "Login",
  mixins: [customComponentActions],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      field: {
        email: "email",
        password: "password",
      },
      isLoading: false,
      errorMessage: null,
      submitted: false,
    };
  },

  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    async login() {
      const me = this;
      me.submitted = true;
      // Metodo de libreria vuelidate
      me.$v.$touch();
      if (me.$v.$invalid) {
        me.submitted = false;
      } else {
        me.isLoading = true;
        try {
          /* const auth = getAuth();
          const result = await signInWithEmailAndPassword(
            auth,
            me.user.email,
            me.user.password
          ); */
          /* const { user } = result;
          localStorage.setItem("access_token", user.accessToken);
          console.log(user); */
        } catch (error) {
          me.user.email = me.user.password = "";
          me.errorMessage = "Usuario y/o contraseña inválidos";
        } finally {
          me.isLoading = false;
        }
      }
    },
    closedPanel() {
      const me = this;
      me.errorMessage = null;
    },
  },
};
</script>

<style>
@import "./login.css";
</style>
