<template>
  <div class="sensors__action mt-3 mb-1">
    <span class="span1">{{ text.text1 }}</span>
      <label class="switch">
        <input type="checkbox" :checked="value" disabled />
        <span class="slider round"></span>
      </label>
    <span class="span2">{{ text.text2 }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    text: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style>
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 6px;
    bottom: 2.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #6ab23c;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #6ab23c;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>